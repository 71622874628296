<template>
  <div>
    <FuNav
      :fixed="false"
      :title="$t('ebClaim.SubmitClaim')"
      :arrow="true"
      percent
    />

    <div class="flex-step">
      <div
        v-for="(item, index) in 3"
        :key="index"
        class="item-step"
        :class="stepDisplay(index)"
      >
        <p class="step-text">{{ index + 1 }}</p>
        <Icon :name="index == step ? 'underway-o' : 'success'" :class="index > step ? 'step-icon normal-icon' : 'step-icon'" />
      </div>
    </div>
  </div>

</template>

<script>
import { Icon } from 'vant'
import FuNav from '@/components/v2/FuNav'

export default {
  components: { Icon, FuNav },
  props: {
    step: { type: Number, default: 0 }
  },
  data() {
    return {
    }
  },
  computed: {
    stepDisplay() {
      return (index) => {
        if (index == this.step) {
          return 'process-step'
        } else if (index < this.step) {
          return 'finish-step'
        } else {
          return 'normal-step'
        }
      }
    }
  },
  methods: {
    goback() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.step-icon {
  font-size: 20px;
}
.normal-icon {
  visibility: hidden;
}
.flex-step {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  gap: 20px;
  padding: 20px;
  border-top: 8px solid #F5F5F5;
  border-bottom: 8px solid #F5F5F5;
  .item-step {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    padding: 5px 20px;
    flex: 1 1;
    .step-text {
      font-size: 15px;
      margin: 0 10px 0 3px;
    }
  }
  .process-step {
    background-color: #1989fa;
    color: #fff;
  }
  .finish-step {
    background-color: #07c160;
    color: #fff;
  }
  .normal-step {
    background-color: #d6d6d6;
    color: #888888;
    .step-text {
      margin: 0;
    }
  }
}
</style>
