<template>
  <div class="step2-div">
    <FuFormMin
      v-if="!loading"
      ref="FuForm"
      v-model="formData"
      class="p10"
      :schema="formColumns"
      :lang="lang"
    />

    <div class="btn-group">
      <div class="btn-group-wapper">
        <Button
          class="fu-v2-btn block"
          round
          block
          type="danger"
          @click="$parent.step = 0"
        >{{ $t('ebClaim.Back') }}</Button>
        <span />
        <Button
          class="fu-v2-btn danger block"
          round
          block
          type="danger"
          @click="saveClaimPortalStep2Form"
        >{{ $t('ebClaim.Next') }}</Button>
      </div>
    </div>
  </div>

</template>

<script>
import FuFormMin from '@/components/FuFormMin'
import { Button } from 'vant'
import { getClaimPortalList, getClaimPortalForm, saveClaimPortalStep2Form } from '@/views/claim/api/index.js'
import { toastLoading } from '@/utils'

export default {
  components: { FuFormMin, Button },
  props: {},
  inject: ['getPolicyItem', 'setStep2Data', 'setReportInfo'],
  data() {
    return {
      loading: true,
      active: '1',
      formData: {},
      formColumns: {},
      dynamicFormInfo: {}
    }
  },
  computed: {
    lang() {
      return this.$store.state.base.lang
    }
  },
  mounted() {
    this.getClaimPortalList()
  },
  methods: {
    async getClaimPortalList() {
      this.loading = toastLoading()
      const params = {
        'processCode': 'PF_00020',
        'productCategory': 'health',
        'status': 1,
        'pageSize': 1000
      }
      const list = await getClaimPortalList(params)
      const res = await getClaimPortalForm({ processCode: list?.data?.[0].processCode })
      this.loading.clear()
      this.loading = null
      this.formColumns = JSON.parse(res.displaySchema)
      this.dynamicFormInfo = {
        productCategory: res.productCategory,
        formProcessCode: res.processCode
      }
    },
    formValid() {
      return new Promise((resolve) => {
        this.$refs.FuForm.validate().then(res => {
          resolve(true)
        }).catch(() => {
          resolve(false) // TODO: 此处需要修改
        })
      })
    },
    async saveClaimPortalStep2Form() {
      const valid = await this.formValid()
      if (!valid) return
      const { policyNo, slipUid, insuredName, insuranceCardNumber } = this.getPolicyItem()
      this.loading = toastLoading()
      const params = {
        insuranceClass: 2,
        ...this.dynamicFormInfo,
        formDataJson: JSON.stringify(this.formData),
        policyNo, slipUid, insuredName, insuranceCardNumber
      }
      const res = await saveClaimPortalStep2Form(params)
      this.setStep2Data(this.formData)
      this.loading.clear()
      this.loading = null
      const { applicationFormNo, reportUid } = res
      if (!reportUid) return
      this.setReportInfo({
        formNo: applicationFormNo,
        reportUid
      })
      this.$parent.step = 2
    }
  }
}
</script>
<style lang="scss" scoped>
.step2-div {
  overflow-y: scroll;
}
.btn-group {
  padding: 15px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  .btn-group-wapper {
    display: flex;
    gap: 10px;
  }
}
</style>
