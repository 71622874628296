<template>
  <div class="">
    <FuSwitch v-model="active" @change="handleChange" />
    <div v-if="active == '1'">
      <FuFormMin
        v-if="showForm"
        ref="FuForm1"
        v-model="insuranceCardData"
        class="p10"
        :schema="getInsuranceCardColumns"
        :lang="lang"
      />
    </div>
    <div v-if="active == '2'">
      <FuFormMin
        v-if="showForm"
        ref="FuForm2"
        v-model="insureInformationData"
        class="p10"
        :schema="getInsureInformationColumns"
        :lang="lang"
      />
    </div>

    <div class="btn-group">
      <Button
        class="fu-v2-btn danger block"
        round
        block
        type="danger"
        @click="handleStep0Click"
      >{{ $t('ebClaim.Next') }}</Button>
    </div>
    <Continue
      v-if="showContinue"
      v-model="showContinue"
      :policy-list="policyList"
    />
    <NotFound v-if="showNotFound" v-model="showNotFound" />
  </div>

</template>

<script>
import Continue from '../continue/index.vue'
import NotFound from './components/notFound/index.vue'
import FuSwitch from './components/Switch'
import FuFormMin from '@/components/FuFormMin'
import { Button } from 'vant'
import { getInsureInformationColumns, getInsuranceCardColumns } from './config/index.js'
import { formatDateTime } from '@fu/core'
import { searchClaimablePolicies } from '@/api/eb.js'
import { toastLoading } from '@/utils'
import dao from '@/utils/dao'

export default {
  components: { FuFormMin, FuSwitch, Button, Continue, NotFound },
  props: {},
  inject: ['setStep1Data', 'getStep1Data'],
  data() {
    return {
      active: '2',
      showForm: false,
      loading: '',
      insuranceCardData: {},
      insureInformationData: {},
      getInsuranceCardColumns: getInsuranceCardColumns(this),
      getInsureInformationColumns: getInsureInformationColumns(this),
      showContinue: false,
      showNotFound: false,
      policyList: []
    }
  },
  computed: {
    lang() {
      return this.$store.state.base.lang
    }
  },
  activated() {
    this.showContinue = false
    this.showNotFound = false
    this.getInsuranceCardColumns = getInsuranceCardColumns(this)
    this.getInsureInformationColumns = getInsureInformationColumns(this)
  },
  mounted() {
    // console.log(123)
    this.init()
  },
  methods: {
    init() {
      this.showContinue = false
      this.showNotFound = false
      this.showForm = false
      const obj = this.getStep1Data()
      this.getInsuranceCardColumns = getInsuranceCardColumns(this)
      this.getInsureInformationColumns = getInsureInformationColumns(this)
      console.log('----', obj)
      if (Object.keys(obj).length === 0) {
        console.log('true了？')
        this.showForm = true
        return
      }
      const { active, ...formData } = obj
      console.log(formData)
      this.active = active
      if (active == '1') {
        setTimeout(() => {
          this.insuranceCardData = { insuranceCard: formData }
          this.showForm = true
        }, 500)
      } else {
        setTimeout(() => {
          this.insureInformationData = { insureInformation: formData }
          this.showForm = true
        }, 500)
      }
    },
    handleChange(e) {
      if (this.active == e) return
      this.active = '3'
      this.$nextTick(() => {
        this.active = e
        this.insuranceCardData = {}
        this.insureInformationData = {}
      })
    },
    formValid() {
      return new Promise((resolve) => {
        this.$refs[`FuForm${this.active}`].validate().then(res => {
          resolve(true)
        }).catch(() => {
          resolve(false) // TODO: 此处需要修改
        })
      })
    },
    async searchClaimablePolicies() {
      const valid = await this.formValid()
      if (!valid) return
      const params = {
        ...this.insuranceCardData?.insuranceCard,
        ...this.insureInformationData?.insureInformation,
        searchType: this.active,
        pageNo: '0'
        // insuranceCardNumber: '14Nov24/01/01',
        // insuredBrith: '11-11-1991'
      }
      const obj = JSON.parse(JSON.stringify(params))
      obj.dol = String(obj.dol).length > 10 ? obj.dol / 1000 : parseInt(obj.dol, 10) // TODO: 时间戳
      obj.insuredBrith = formatDateTime(obj.insuredBrith, 'dd-MM-yyyy')
      this.loading = toastLoading()
      const res = await searchClaimablePolicies(obj)
      this.loading.clear()
      this.loading = null
      this.policyList = res?.claimablePolicies?.data // TODO: 删除
      if (!this.policyList.length) {
        this.showNotFound = true
        return
      }
      const token = res.tmpVisitToken
      dao.set('tempToken', token)
      this.setStep1Data({ ...this.insuranceCardData?.insuranceCard, ...this.insureInformationData?.insureInformation, active: this.active })
      this.showContinue = true
    },
    handleStep0Click() {
      // this.$parent.step = 1
      this.searchClaimablePolicies()
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-group {
  padding: 15px;
}

</style>
