<template>
  <div class="continue-div">
    <FuNav
      :fixed="false"
      :title="$t('ebClaim.SubmitClaim')"
      :arrow="true"
      :back="() => {$emit('input', false)}"
      percent
    />
    <div class="img-dev">
      <img class="img2" :src="portal1">
      <p class="continue-t">{{ $t('ebClaim.YourPolicyTitle') }}</p>
      <p>{{ $t('ebClaim.YourPolicyText') }}</p>

      <div
        v-for="(item, index) in policyList"
        :key="index"
        class="card-div"
        :class="{ 'card-div-active': selectIndex === index }"
        @click="selectIndex = index"
      >
        <div class="card-insure-company">
          <img :src="item.insuranceLogo" alt=""><div class="insure-status">Active</div>
        </div>
        <p class="card-title bold">{{ item.insuredName }}</p>
        <p class="card-title">{{ item.rawPlanName }}</p>
        <P class="card-title">{{ item.rawName }}</P>

        <div class="card-info-group">
          <div class="card-info-item"><span class="info-left">{{ $t('ebClaim.InsuranceCardNumber') }}</span><span class="info-right">{{ item.insuranceCardNumber }}</span></div>
          <div class="card-info-item"><span class="info-left">{{ $t('ebClaim.PolicyNo') }}</span><span class="info-right">{{ item.policyNo }}</span></div>
          <div class="card-info-item"><span class="info-left">{{ $t('ebClaim.ActiveDate') }}</span><span class="info-right">{{ formatDateTime(item.startTime) }} - {{ formatDateTime(item.endTime) }}</span></div>
        </div>
      </div>
    </div>

    <div class="btn-group">
      <div class="continue-btn-group">
        <Button
          class="fu-v2-btn danger block"
          :disabled="!selectIndex && selectIndex !== 0"
          round
          block
          type="danger"
          @click="handleNextBtnClick"
        >{{ $t('ebClaim.Next') }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import FuNav from '@/components/v2/FuNav'
import portal1 from '@/assets/imgs/portal26.png'
import portal2 from '@/assets/imgs/portal27.png'
import { Button } from 'vant'
import { dispatchAction } from '@/utils/dispatch'
import { formatDateTime } from '@fu/core'
export default {
  components: { Button, FuNav },
  props: {
    value: { type: Boolean, default: false },
    policyList: { type: Array, default: () => [] }
  },
  inject: ['setPolicyItem'],
  data() {
    return {
      portal1, portal2,
      active: 2,
      selectIndex: ''
    }
  },
  computed: {
    formatDateTime() {
      return (val) => {
        return formatDateTime(val, '', '', 'ss')
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.policyList.length > 0) {
        this.selectIndex = 0
      }
    },
    onBackHomepage() {
      dispatchAction({
        method: 'jumpToHomePage'
      })
    },
    handleNextBtnClick() {
      if (!this.selectIndex && this.selectIndex != 0) return
      this.setPolicyItem(this.policyList[this.selectIndex])
      this.$emit('input', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.continue-div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 91;
  background-color: #fff;
  overflow: scroll;
}
.img-dev {
  margin-top: 28px;
  text-align: center;
  // margin-bottom: 100px;
}
.img1 {
  width: 186px;
}
.img2 {
  width: 260px;
}
.continue-claim-info {
  margin: 10px;
}
.card-div-active {
  border: 1px solid #E74039!important;
}
.card-div {
  border: 1px solid #EFEDED;
  border-radius: 13px;
  margin: 10px;
  padding: 10px;
  text-align: left;
  .card-insure-company {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .insure-status {
      color: #08B752;
      background-color: #eefff5;
      padding: 4px 8px;
    }
    img {
      width: 59px;
    }
  }
  .card-title {
    font-size: 14px;
    margin: 10px 0px;
  }
  .bold {
    font-family: 'Montserrat-Bold';
  }
}
.card-info-group {
  margin-top: 10px;
  // padding: 0 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  .card-info-item {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    .info-left {
      text-align: left;
      width: 50%;
    }
    .info-right {
      text-align: right;
      width: 50%;
    }
  }
}
.btn-group {
  padding: 15px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  .continue-btn-group {
    display: flex;
    gap: 10px;
  }
}
</style>
