<template>
  <div class="main-wrapper">
    <div class="claim-wrapper">
      <StepNav :step="step" />
      <Step1 v-if="step === 0" />
      <Step2 v-if="step === 1" />
      <Step3 v-if="step === 2" />
    </div>
  </div>
  

</template>

<script>
import StepNav from '../components/step/index.vue'
import Step1 from '../components/step1/index.vue'
import Step2 from '../components/step2/index.vue'
import Step3 from '../components/step3/index.vue'
import('@/views/claim/eb/css/reset.css')
export default {
  components: { StepNav, Step1, Step2, Step3 },
  props: {},
  provide() {
    return {
      setStep: (val) => {  this.step = val },
      showContinue: () => {this.showContinue = true},
      setPolicyItem: this.setPolicyItem,
      getPolicyItem: () => this.policyItem,
      setStep1Data: (val) => {this.step1Data = val},
      getStep1Data: () => this.step1Data,
      setStep2Data: (val) => {this.step2Data = val},
      getStep2Data: () => this.step2Data,
      setReportInfo: (val) => {this.reportInfo = val},
      getReportInfo: () => this.reportInfo
      
    }
  },
  activated() {
    this.init()
  },
  data() {
    return {
      step: 0,
      step1Data: {},
      step2Data: {},
      step3Data: {},
      showContinue: false,
      policyItem: {},
      reportInfo: {}
    }
  },
  methods: {
    init() {
      this.step = 0
      this.step1Data = {}
      this.step2Data = {}
      this.step3Data = {}
      this.showContinue = false
      this.policyItem = {}
      this.reportInfo = {}
    },
    setPolicyItem(val) {
      this.step = 1
      this.policyItem =  val
    }
  }
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  height: 100vh;
  width: 100vw;
  background: #f5f5f5;
}
@media (min-width: 600px) {
  .claim-wrapper {
    width: 600px;
  }
}
.claim-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
