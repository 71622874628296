<template>
  <div class="verify-div">
    <FuNav
      :fixed="false"
      :title="$t('ebClaim.SubmitClaim')"
      :arrow="true"
      :back="() => {$emit('input', false)}"
      percent
    />
    <div class="img-dev">
      <img class="img1" :src="portal30">
      <p class="verify-t">{{ $t('ebClaim.PolicyNotFound') }}</p>
      <p class="verify-s">{{ $t('ebClaim.PolicyNotFoundDesc') }}</p>
    </div>

    <div class="btn-group">
      <!-- <Button
        class="fu-v2-btn block"
        round
        block
        type="danger"
        @click="$router.push({name: 'EbTrackClaim'})"
      >{{ $t('ebClaim.TrackingNotFoundBackBtn') }}</Button> -->
      <Button
        class="fu-v2-btn danger block"
        round
        block
        type="danger"
        @click="onBackHomepage"
      >{{ $t('back') }}</Button>
    </div>
  </div>

</template>

<script>
import FuNav from '@/components/v2/FuNav'
import { Button } from 'vant'
import portal30 from '@/assets/imgs/portal30.png'
export default {
  components: { Button, FuNav },
  props: {},
  data() {
    return {
      portal30
    }
  },
  methods: {
    onBackHomepage() {
      this.$emit('input', false)
      // this.$router.push({ name: 'EbClaimPortal' })
    }
  }
}
</script>
<style lang="scss" scoped>
.verify-div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 91;
  background-color: #fff;
}
.img-dev {
  margin-top: 111px;
  text-align: center;
}
.img1 {
  width: 270px;
}
.verify-t {
  font-size: 16px;
  font-family: 'Montserrat-Bold';
  margin: 15px 0;
}
.verify-s {
  font-size: 12px;
  margin: 0 45px;
  text-align: center;
}
.btn-group {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}
</style>
