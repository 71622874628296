import i18n from '@/i18n/i18n.js'

export const getInsuranceCardColumns = (vm) => {
  return {
    'schema': {
      'type': 'object',
      'title': 'create form',
      'description': '',
      'properties': {},
      'required': []
    },
    'columns': [
      {
        'name': '',
        'schema': {
          'type': 'object',
          'description': '自定义对象组',
          'properties': {},
          'required': []
        },
        'fieldJson': {
          'noteStyle': {},
          'tipTitleStyle': {},
          'tipContentStyle': {},
          'extraConfig': {},
          'required': true,
          'show': true,
          'tipShowType': 'auto',
          'isCustom': true,
          'ocrSwitch': []
        },
        'renderType': 'Object',
        'columns': [
          {
            'name': i18n.t('ebClaim.InsuranceCardNumber'),
            'schema': {
              'type': 'string',
              'description': '自定义文本输入框',
              'pattern': '',
              'patternMessage': '',
              'patternMessageLocal': ''
            },
            'fieldJson': {
              'noteStyle': {},
              'tipTitleStyle': {},
              'tipContentStyle': {},
              'extraConfig': {},
              'required': true,
              'show': true,
              'tipShowType': 'auto',
              'suffix': '',
              'defaultValueSource': 1,
              'displayRelyGather': [],
              'defaultValueOriginParams': [],
              'defaultValueOriginCfg': {
                'method': 'POST'
              },
              'isCustom': true,
              'extendDisplayCfg': [],
              'requiredMessage': `${i18n.t('ebClaim.InsuredCardNumber')} is required`,
              'requiredMessageLocal': `${i18n.t('ebClaim.InsuredCardNumber')} is required`
            },
            'renderType': 'Input',
            'key': 'insuranceCardNumber',
            'renderKey': '1725359494969_94265'
          },
          {
            'name': i18n.t('ebClaim.Birthday'),
            'schema': {
              'type': 'integer',
              'description': '自定义日期'
            },
            'fieldJson': {
              'noteStyle': {},
              'tipTitleStyle': {},
              'tipContentStyle': {},
              'extraConfig': {},
              'required': true,
              'show': true,
              'tipShowType': 'auto',
              'minDate': '-66',
              'maxDate': '0',
              'minDateUnit': 1,
              'maxDateUnit': 1,
              'dateType': 'date',
              'dateStartUnit': 1,
              'defaultValueSource': 1,
              'displayRelyGather': [],
              'defaultValueOriginParams': [],
              'defaultValueOriginCfg': {
                'method': 'POST'
              },
              'isCustom': true,
              'extendDisplayCfg': [],
              'requiredMessage': `${i18n.t('ebClaim.Birthday')} is required`,
              'requiredMessageLocal': `${i18n.t('ebClaim.Birthday')} is required`
            },
            'renderType': 'Date',
            'key': 'insuredBrith',
            'renderKey': '1728615009758_74538'
          },
          {
            'name': i18n.t('ebClaim.DateofLoss'),
            'schema': {
              'type': 'integer',
              'description': '自定义日期'
            },
            'fieldJson': {
              'noteStyle': {},
              'tipTitleStyle': {},
              'tipContentStyle': {},
              'extraConfig': {},
              'required': true,
              'show': true,
              'tipShowType': 'auto',
              'minDate': '',
              'maxDate': '0',
              'minDateUnit': 1,
              'maxDateUnit': 1,
              'dateType': 'date',
              'dateStartUnit': 1,
              'defaultValueSource': 1,
              'displayRelyGather': [],
              'defaultValueOriginParams': [],
              'defaultValueOriginCfg': {
                'method': 'POST'
              },
              'isCustom': true,
              'extendDisplayCfg': [],
              'requiredMessage': `${i18n.t('ebClaim.DateofLoss')} is required`,
              'requiredMessageLocal': `${i18n.t('ebClaim.DateofLoss')} is required`
            },
            'renderType': 'Date',
            'key': 'dol',
            'renderKey': '1728615009758_74538'
          }
        ],
        'key': 'insuranceCard',
        'renderKey': '1725359475465_46497',
        'aliasName': ''
      }
    ],
    'fieldJson': {
      'version': '2.7.0',
      'hasClause': false,
      'clauseContent': '',
      'clauseContentLocal': '',
      'styleVersion': 'V2',
      'hasTerms': false,
      'termsLs': []
    },
    'config': {
      'labelWidth': 200
    }
  }
}

export const getInsureInformationColumns = (vm) => {
  return {
    'schema': {
      'type': 'object',
      'title': 'create form',
      'description': '',
      'properties': {},
      'required': []
    },
    'columns': [
      {
        'name': '',
        'schema': {
          'type': 'object',
          'description': '自定义对象组',
          'properties': {},
          'required': []
        },
        'fieldJson': {
          'noteStyle': {},
          'tipTitleStyle': {},
          'tipContentStyle': {},
          'extraConfig': {},
          'required': true,
          'show': true,
          'tipShowType': 'auto',
          'isCustom': true,
          'ocrSwitch': []
        },
        'renderType': 'Object',
        'columns': [
          {
            'name': i18n.t('ebClaim.EmployeeName'),
            'schema': {
              'type': 'string',
              'description': '自定义文本输入框',
              'pattern': '',
              'patternMessage': '',
              'patternMessageLocal': ''
            },
            'fieldJson': {
              'noteStyle': {},
              'tipTitleStyle': {},
              'tipContentStyle': {},
              'extraConfig': {},
              'required': true,
              'show': true,
              'tipShowType': 'auto',
              'suffix': '',
              'defaultValueSource': 1,
              'displayRelyGather': [],
              'defaultValueOriginParams': [],
              'defaultValueOriginCfg': {
                'method': 'POST'
              },
              'isCustom': true,
              'extendDisplayCfg': [],
              'requiredMessage': `${i18n.t('ebClaim.EmployeeName')} is required`,
              'requiredMessageLocal': `${i18n.t('ebClaim.EmployeeName')} is required`
            },
            'renderType': 'Input',
            'key': 'insuredName',
            'renderKey': '1725359494969_94265'
          },
          {
            'name': i18n.t('ebClaim.EmployeeDateOfBirth'),
            'schema': {
              'type': 'integer',
              'description': '自定义日期'
            },
            'fieldJson': {
              'noteStyle': {},
              'tipTitleStyle': {},
              'tipContentStyle': {},
              'extraConfig': {},
              'required': true,
              'show': true,
              'tipShowType': 'auto',
              'minDate': '-66',
              'maxDate': '0',
              'minDateUnit': 1,
              'maxDateUnit': 1,
              'dateType': 'date',
              'dateStartUnit': 1,
              'defaultValueSource': 1,
              'displayRelyGather': [],
              'defaultValueOriginParams': [],
              'defaultValueOriginCfg': {
                'method': 'POST'
              },
              'isCustom': true,
              'extendDisplayCfg': [],
              'requiredMessage': `${i18n.t('ebClaim.EmployeeDateOfBirth')} is required`,
              'requiredMessageLocal': `${i18n.t('ebClaim.EmployeeDateOfBirth')} is required`
            },
            'renderType': 'Date',
            'key': 'insuredBrith',
            'renderKey': '1728615009758_74538'
          },
          {
            'name': i18n.t('ebClaim.EmployeeNationID'),
            'schema': {
              'type': 'string',
              'description': '自定义文本输入框',
              'pattern': '',
              'patternMessage': '',
              'patternMessageLocal': ''
            },
            'fieldJson': {
              'noteStyle': {},
              'tipTitleStyle': {},
              'tipContentStyle': {},
              'extraConfig': {},
              'required': true,
              'show': true,
              'tipShowType': 'auto',
              'suffix': '',
              'defaultValueSource': 1,
              'displayRelyGather': [],
              'defaultValueOriginParams': [],
              'defaultValueOriginCfg': {
                'method': 'POST'
              },
              'isCustom': true,
              'extendDisplayCfg': [],
              'requiredMessage': `${i18n.t('ebClaim.EmployeeNationID')} is required`,
              'requiredMessageLocal': `${i18n.t('ebClaim.EmployeeNationID')} is required`
            },
            'renderType': 'Input',
            'key': 'insuredIdCard',
            'renderKey': '1725359494969_94265'
          },
          {
            'name': i18n.t('ebClaim.DateofLoss'),
            'schema': {
              'type': 'integer',
              'description': '自定义日期'
            },
            'fieldJson': {
              'noteStyle': {},
              'tipTitleStyle': {},
              'tipContentStyle': {},
              'extraConfig': {},
              'required': true,
              'show': true,
              'tipShowType': 'auto',
              'minDate': '',
              'maxDate': '0',
              'minDateUnit': 1,
              'maxDateUnit': 1,
              'dateType': 'date',
              'dateStartUnit': 1,
              'defaultValueSource': 1,
              'displayRelyGather': [],
              'defaultValueOriginParams': [],
              'defaultValueOriginCfg': {
                'method': 'POST'
              },
              'isCustom': true,
              'extendDisplayCfg': [],
              'requiredMessage': `${i18n.t('ebClaim.DateofLoss')} is required`,
              'requiredMessageLocal': `${i18n.t('ebClaim.DateofLoss')} is required`
            },
            'renderType': 'Date',
            'key': 'dol',
            'renderKey': '1728615009758_74538'
          }
        ],
        'key': 'insureInformation',
        'renderKey': '1725359475465_46497',
        'aliasName': ''
      }
    ],
    'fieldJson': {
      'version': '2.7.0',
      'hasClause': false,
      'clauseContent': '',
      'clauseContentLocal': '',
      'styleVersion': 'V2',
      'hasTerms': false,
      'termsLs': []
    },
    'config': {
      'labelWidth': 200
    }
  }
}

